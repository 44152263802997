import React from 'react';
import Navbar from './Navbar';
import Header from "./Header";


const Home = () => {
  return (
    <div>

      <Navbar/>
      <Header />
    </div>
  )
}

export default Home
